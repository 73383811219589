<template>
    <div class="icon-box">
        <transition-group name="icon" tag="div">
            <div
                v-for="icon in icons"
                :key="icon.name"
                v-show="index === activeIndex"
                class="icon"
            >
                <img :src="icon" alt="icon" />
            </div>
        </transition-group>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            icons: [],
            activeIndex: 0,
            timeout: null,
        };
    },
    mounted() {
        this.startCycling();
        this.mapIcons();
    },
    computed: {
        ...mapState("options", ["giftOptions"]),
    },
    methods: {
        mapIcons() {
            this.icons = this.giftOptions.map((gift) => gift.url);
        },
        startCycling() {
            const cycle = () => {
                this.activeIndex = (this.activeIndex + 1) % this.icons.length;
                setTimeout(() => requestAnimationFrame(cycle), 100);
            };
            requestAnimationFrame(cycle);
        },
    },
    beforeDestroy() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    },
};
</script>

<style scoped>
.icon-box {
    position: relative;
    width: 100px; /* Set width according to your design */
    height: 100px; /* Set height according to your design */
    overflow: hidden;
}

.icon-enter-active,
.icon-leave-active {
    transition: opacity 0.1s ease-in-out;
}

.icon-enter,
.icon-leave-to {
    opacity: 0;
}

.icon {
    position: absolute;
    width: 100%; /* Ensure the icon takes full width */
    height: 100%; /* Ensure the icon takes full height */
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon img {
    width: 100%;
    height: 100%;
    transform: translateZ(0); /* Trigger GPU acceleration */
}
</style>
