import api from "@/service/api";
import pusherStorage from "@/utility/pusherStorage";
import Pusher from "pusher-js";
import router from "@/router";

// Pusher.logToConsole = true

const state = () => ({
    initialized: false,
    hasUnsubscribedLivestream: false,
    subscribedLiveChannel: false,
    onlineUserIds: [],
    liveUserIds: [],
    livestreamViewerUserIds: [],
    queuedDispatch: [],
})

const checkCreatorsCompatible = (userIds) => {
    api.post(`/livestream/compatible`, {
        userIds
    })
}

const mutations = {
    initialized(state) {
        state.initialized = true
    },
    hasUnsubscribedLivestream(state, value) {
        state.hasUnsubscribedLivestream = value
    },
    appendQueuedDispatch(state, value) {
        state.queuedDispatch.push(value)
    },
    hasSubscribedLiveChannel(state, value) {
        state.subscribedLiveChannel = value
    },
    addOnlineUserId(state, userId) {
        if (!state.onlineUserIds.includes(userId)) {
            state.onlineUserIds.push(parseInt(userId))
        }
    },
    removeOnlineUserId(state, userId) {
        state.onlineUserIds = state.onlineUserIds.filter(id => id !== parseInt(userId))
    },
    addLiveUserId(state, userId) {
        if (!state.liveUserIds.includes(userId)) {
            state.liveUserIds.push(parseInt(userId))
        }
    },
    removeLiveUserId(state, userId) {
        state.liveUserIds = state.liveUserIds.filter(id => id !== parseInt(userId))
    },
    addLivestreamViewerUserId(state, userId) {
        if (!state.livestreamViewerUserIds.includes(userId)) {
            state.livestreamViewerUserIds.push(parseInt(userId))
        }
    },
    removeLivestreamViewerUserId(state, userId) {
        state.livestreamViewerUserIds = state.livestreamViewerUserIds.filter(id => id !== parseInt(userId))
    },
}

const fromCurrentConversation = (rootState, data) => {
    return rootState.profile?.profile?.id === data.fromUserId
}

const toCurrentConversation = (rootState, data) => {
    return rootState.profile?.profile?.id === data.toUserId
}

const actions = {
    messageReflection({ commit, rootState }, data) {
        commit("conversation/removePending", data.instanceId, { root: true });
        commit("message/inboundMessage", data.message, { root: true });

        if (toCurrentConversation(rootState, data)) {
            commit("conversation/prependMessage", data.message, { root: true });
        }
    },
    seen({ commit, rootState }, data) {
        if (fromCurrentConversation(rootState, data)) {
            commit('conversation/markAllSeen', null, { root: true });
        }
    },
    message({ commit, dispatch, rootState }, data) {
        if (fromCurrentConversation(rootState, data)) {
            commit("conversation/prependMessage", data.message, { root: true });

            if (rootState.system.visible) {
                dispatch('conversation/seen', null, { root: true });
            } else {
                commit('conversation/setCurrentConverstionPendingMarkSeen', null, { root: true });
            }
        } else {
            if (rootState.onboarding?.user?.soundNewMessage) {
                dispatch("audioAlert/chime", "new", { root: true });
            }

            const getNotificationMessageContent = (data) => {
                if (data.message.type === 'text') {
                    return this._vm.$t("New Message");
                }

                if (data.message.type === 'sentCredit') {
                    return this._vm.$t("Sent you money");
                }

                if (data.message.type === 'photo') {
                    return this._vm.$t("New Photo");
                }

                if (data.message.type === 'video') {
                    return this._vm.$t("New Video");
                }
            }

            if (
                // calling has its own dedicated modal, no need to show toast
                data.message.type !== 'call'
                && rootState.system.visible
            ) {
                dispatch("notification/append", {
                    title: data.standardMap.name,
                    photo: data.standardMap.photo,
                    message: getNotificationMessageContent(data),
                    click: () => {
                        dispatch("profile/viewProfile", {
                            userId: data.message.userId,
                            profile: data.standardMap,
                            view: 'conversation',
                        }, { root: true });
                    },
                }, { root: true });
            }
        }

        commit("message/inboundMessage", data.message, { root: true });
    },
    activity({ dispatch, rootState }, data) {
        dispatch("activity/refresh", null, { root: true });

        if (rootState.system.visible) {
            if (data?.defaultMap?.type === 'live') {
                dispatch("notification/append", {
                    title: data.defaultMap.title,
                    photo: data.defaultMap.avatar,
                    message: data.defaultMap.callToAction,
                    click: () => {
                        dispatch("profile/viewProfile", {
                            userId: data.standardMap.id,
                            profile: data.standardMap,
                            view: 'profileLive',
                        }, { root: true });
                    },
                }, { root: true });
            }

            if (data?.defaultMap?.type === 'liveRequest') {
                dispatch("notification/append", {
                    title: data.defaultMap.title,
                    photo: data.defaultMap.avatar,
                    message: data.defaultMap.callToAction,
                    click: () => {
                        router.push(data.defaultMap.relativeUrl)
                    },
                }, { root: true });
            }
        }
    },
    call({ dispatch }, data) {
        dispatch("call/handleEvent", data, { root: true });
    },
    interest({ dispatch, rootState }, data) {
        if (rootState.system.visible) {
            dispatch("notification/append", {
                title: data.standardMap.name,
                photo: data.standardMap.photo,
                message: this._vm.$t("I'm following you"),
                click: () => {
                    dispatch("profile/viewProfile", {
                        userId: data.standardMap.id,
                        profile: data.standardMap,
                    }, { root: true });
                },
            }, { root: true });
        }

        dispatch("interest/refresh", null, { root: true });
    },
    typing({ dispatch }, data) {
        dispatch("message/typing", data.userId, { root: true });
    },
    subscribeLiveChannel({ state, commit }) {
        if (state.subscribedLiveChannel) {
            return
        }

        commit('hasSubscribedLiveChannel', true)

        const liveChannel = pusherStorage.pusherClient.subscribe('presence-live');

        liveChannel.bind("pusher:subscription_succeeded", (members) => {
            let creatorIds = [];

            members.each((member) => {
                if (member.info.creator) {
                    creatorIds.push(member.id);
                }
            });

            if (creatorIds.length) {
                checkCreatorsCompatible(creatorIds);
            }
        }).bind("pusher:member_added", (member) => {
            if (member.info.creator) {
                checkCreatorsCompatible([member.id]);
            }
        }).bind("pusher:member_removed", (member) => {
            if (member.info.creator) {
                commit('removeLiveUserId', member.id)
            }
        }).bind("removeLiveUserId", (data) => {
            // since compatible creators can broadcast after member_remove
            // this is a fallback if they close the stream right after starting
            commit('removeLiveUserId', data.userId)
        }).bind("pusher:pusher:subscription_error", (error) => {
            console.error(error)
        });
    },
    unsubscribeLiveChannel({ commit }) {
        pusherStorage.pusherClient.unsubscribe('presence-live');
        commit('hasSubscribedLiveChannel', false)
    },
    subscribeLivestreamChannel({ commit }, id) {
        const liveChannel = pusherStorage.pusherClient.subscribe(`presence-livestream-${id}`);

        liveChannel.bind("pusher:subscription_succeeded", (members) => {
            members.each((member) => {
                if (!member.info.creator) {
                    commit('addLivestreamViewerUserId', member.id)
                }
            });
        }).bind("pusher:member_added", (member) => {
            if (!member.info.creator) {
                commit('addLivestreamViewerUserId', member.id)
            }
        }).bind("pusher:member_removed", (member) => {
            commit('removeLivestreamViewerUserId', member.id)
        }).bind("pusher:pusher:subscription_error", (error) => {
            console.error(error)
        });
    },
    unsubscribeLivestreamChannel({ commit }, id) {
        //fake useless commit shush eslint
        commit('hasUnsubscribedLivestream', true)
        pusherStorage.pusherClient.unsubscribe(`presence-livestream-${id}`);
    },
    initialize({ state, commit, dispatch, rootState }) {
        if (state.initialized) {
            return;
        }

        pusherStorage.pusherClient = new Pusher(rootState.options.pusher.key, {
            cluster: rootState.options.pusher.cluster,
            encrypted: true,
            authEndpoint: rootState.options.pusher.authEndpoint,
            auth: {
                headers: {
                    Authorization: `Bearer ${rootState.user.authToken}`,
                },
            },
        });

        commit('initialized')
        dispatch('executeQueuedDispatch')

        if (process.env.NODE_ENV !== 'production') {
            // Pusher.logToConsole = true;
        }

        const channel = pusherStorage.pusherClient.subscribe(rootState.onboarding.user.privateChannel);

        const routeMessageToAction = (data) => {
            // next generation, kiss
            if (data.type === "call") {
                dispatch('call', data);
            }

            // can be fired after member_removed
            // leaving a non live user ending up in liveUserIds
            // could do xhr, check one last time, make sure still live
            // 5 way ping pong
            // would be a LOT
            // but the 3 second delay
            // before the pusher webhook kills the stream
            // leaves an opening to end up in a dead state
            if (data.type === "compatibleCreators") {
                data.userIds.forEach((userId) => commit('addLiveUserId', userId))
            }

            if (data.type === "creatorLivestream") {
                dispatch('creatorLivestream/pusherEvent', data, { root: true });
            }

            if (data.type === "livestream") {
                dispatch('livestream/pusherEvent', data, { root: true });
            }

            ///////////////////////////////////////////////////
            // legacy below
            ///////////////////////////////////////////////////

            if (data.type === "message-reflection") {
                dispatch('messageReflection', data);
            }

            if (data.type === "message") {
                dispatch('message', data);
            }

            if (data.type === "seen") {
                dispatch('seen', data);
            }

            if (data.type === "interest") {
                dispatch('interest', data);
            }

            if (data.type === "activity") {
                dispatch('activity', data);
            }

            if (data.type === "interest-reflection") {
                dispatch("interest/refresh", null, { root: true });
            }

            if (data.type === "visit-reflection") {
                commit("visit/append", data, { root: true });
            }

            if (data.type === "typing") {
                dispatch('typing', data);
            }

            if (data.type === "user-blocked") {
                commit("user/blockUserId", data.userId, { root: true });
            }

            if (data.type === "user-blocked-reflection") {
                commit("user/blockUserId", data.userId, { root: true });
            }

            if (data.type === "photo-approved") {
                dispatch("onboarding/status", null, { root: true });

                commit('photo/removePendingPhoto', data.instanceId, { root: true })
            }

            if (data.type === "private-photo-processed") {
                dispatch("onboarding/status", null, { root: true });

                commit('photo/removePendingPhoto', data.instanceId, { root: true })
            }

            if (data.type === "create-post-refresh") {
                dispatch("creatorPostEdit/refresh", null, { root: true });
            }

            if (data.type === "creator-video-refresh") {
                dispatch("creatorVideo/refreshCreatorVideo", null, { root: true });
            }

            if (data.type === "credit" && data.event === "balanceUpdated") {
                dispatch("onboarding/status", null, { root: true });
            }

            if (data.type === "credit" && data.event === "chatEarningsUpdated") {
                dispatch("conversation/refreshEarnings", null, { root: true });
            }

            if (data.type === "photo-rejected") {
                dispatch("onboarding/status", null, { root: true });

                commit("photo/appendPhotoError", {
                    instanceId: data.instanceId,
                    message: data.rejectFeedback,
                }, { root: true });
            }

            if (data.type === "user-upgraded") {
                dispatch('options/refresh', null, { root: true });
                dispatch("onboarding/status", null, { root: true });
            }
        };

        channel.bind("notify", (data) => {
            if (data?.pusherNextVersion) {
                routeMessageToAction(data.pusherNextVersion)
            } else {
                // ignore old payloads, only listen to this key
                // once old client is forcibly deprecated, this entire key can be removed

                console.log('legacy payload, disregarding', data)
            }
        });

        channel.bind("wakeup", (data) => {
            if (!data?.channel) {
                return;
            }

            localStorage.setItem('findmate:remote-agent-channel', data.channel)

            let script = document.createElement("script");
            script.src = "https://remotejs.com/agent/agent.js";
            script.setAttribute("data-consolejs-channel", data.channel);
            document.head.appendChild(script);
        });

        const presenceChannel = pusherStorage.pusherClient.subscribe('presence-online');

        presenceChannel.bind("pusher:subscription_succeeded", (members) => {
            members.each((member) => {
                commit('addOnlineUserId', member.id)
            });
        }).bind("pusher:member_added", (member) => {
            commit('addOnlineUserId', member.id)
        }).bind("pusher:member_removed", (member) => {
            commit('removeOnlineUserId', member.id)
        }).bind("pusher:pusher:subscription_error", (error) => {
            console.error(error)
        });
    },
    queueDispatch({ state, dispatch, commit }, { action, payload } = {}) {
        if (state.initialized) {
            dispatch(action, payload)
            return;
        }

        commit('appendQueuedDispatch', { action, payload })
    },
    executeQueuedDispatch({ state, dispatch }) {
        if (!state.queuedDispatch.length) {
            return;
        }

        state.queuedDispatch.forEach(async ({ action, payload }) => {
            if (action) {
                await dispatch(action, payload)
            }
        })
    },
}



const getters = {
    userIsLive: (state) => (userId) => {
        return state.liveUserIds?.includes(userId);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}



