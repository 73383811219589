import api from "@/service/api";

const state = () => ({
    callCount: null,
    creatorVideoFavoriteCount: null,
    creatorRequestCount: null,
    purchasedGalleryCount: null,
    unseenCreatorRequestCount: null,
})

const mutations = {
    setCallCount(state, value) {
        state.callCount = value;
    },
    setCreatorVideoFavoriteCount(state, value) {
        state.creatorVideoFavoriteCount = value;
    },
    setCreatorRequestCount(state, value) {
        state.creatorRequestCount = value;
    },
    setPurchasedGalleryCount(state, value) {
        state.purchasedGalleryCount = value;
    },
    setUnseenCreatorRequestCount(state, value) {
        state.unseenCreatorRequestCount = value;
    },
}

const actions = {
    refresh({ commit }) {
        api.post('/account/dashboard').then((response) => {
            commit('setCallCount', response?.data?.callCount)
            commit('setCreatorVideoFavoriteCount', response?.data?.creatorVideoFavoriteCount)
            commit('setCreatorRequestCount', response?.data?.creatorRequestCount)
            commit('setPurchasedGalleryCount', response?.data?.purchasedGalleryCount)
            commit('setUnseenCreatorRequestCount', response?.data?.unseenCreatorRequestCount)
        });
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}