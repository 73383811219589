<template>
    <div>
        <h5>Current Payout</h5>
        <b-table-simple>
            <tbody>
                <tr>
                    <th class="bg-light">#1 Video Payout</th>
                    <td class="bg-white">
                        <coin-badge
                            :amount="config?.creatorVideoWeeklyPayoutCredits"
                        />
                    </td>
                </tr>
                <tr>
                    <th class="bg-light">Local Value</th>
                    <td class="bg-white">
                        {{ config?.creatorVideoWeeklyPayoutCreditsLocal }}
                    </td>
                </tr>
            </tbody>
        </b-table-simple>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("options", ["config"]),
    },
};
</script>


