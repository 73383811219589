<template>
    <div>
        <generic-loading />
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
    // determines the homepage for a user
    // as the route for '/'
    // it will simply redirect to the correct place
    // seems to work
    created() {
        // this redirect now causes a profile/close vuex event
        // becauase its trying to close all layers upon route change
        if (!this.isLoggedIn) {
            this.$router.push("/login?dontCloseLayers=1");
        }

        if (this.onboardingUser) {
            if (this.onboardingUser.creator) {
                this.$router.push("/creator?dontCloseLayers=1");
            } else if (this.onboardingUser.affiliate) {
                this.$router.push("/affiliate?dontCloseLayers=1");
            } else {
                this.$router.push("/browse/search?dontCloseLayers=1");
            }
        }
    },
    computed: {
        ...mapState({
            onboardingUser: (state) => state.onboarding.user,
        }),
        ...mapGetters("user", ["isLoggedIn"]),
    },
};
</script>
        
        