
<template>
    <div
        id="video-index-swiper"
        class="d-flex flex-column full-height-page-including-submenu"
    >
        <div class="flex-grow-1 d-flex align-items-center">
            <div
                class="swiper swiper-video shadow position-relative"
                ref="videoIndexSwiper"
            >
                <div class="swiper-wrapper">
                    <div
                        class="swiper-slide"
                        v-for="{ creatorVideo } in creatorVideos"
                        :key="creatorVideo.id"
                    >
                        <div v-if="sharing">
                            <video-share-dialog />
                        </div>
                        <div v-if="gifting">
                            <video-gift-dialog />
                        </div>
                        <video-view :creatorVideo="creatorVideo" />
                    </div>
                    <div class="swiper-slide">
                        <div
                            class="loading d-flex align-items-center justify-content-center h-100"
                        >
                            <div v-if="reachedTheEnd" class="px-3">
                                <h3>The End</h3>
                                You've reached the end of the list, check back
                                later to see new videos, or change your filters
                                to show more results
                                <div class="mt-3">
                                    <b-btn
                                        variant="primary"
                                        @click="startOver"
                                        pill
                                    >
                                        Start Over
                                    </b-btn>
                                </div>
                            </div>
                            <div v-else>
                                <generic-loading />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="swiper-pagination"></div>
                <div class="swiper-actions">
                    <video-action-avatar />

                    <video-action-page
                        v-if="swiper"
                        @prevSlide="prevSlide"
                        @nextSlide="nextSlide"
                    />

                    <video-action-favorite />

                    <video-action-gift />

                    <video-action-share :creatorVideo="currentCreatorVideo" />
                </div>
                <div class="swiper-toolbar">
                    <video-toolbar />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.swiper-actions {
    position: absolute;
    bottom: 100px;
    right: 0;
    z-index: 1;
    width: 4rem;
}
.swiper-toolbar {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 100%;
}
</style>


<script>
import { mapState } from "vuex";

import Swiper, { Mousewheel, Navigation } from "swiper";

import "swiper/swiper-bundle.css";

Swiper.use([Navigation, Mousewheel]);

import VideoView from "@/components/video/VideoView";
import VideoShareDialog from "@/components/video/VideoShareDialog";
import VideoGiftDialog from "@/components/video/VideoGiftDialog";
import VideoToolbar from "@/components/video/VideoToolbar";
import VideoActionFavorite from "@/components/video//action/VideoActionFavorite";
import VideoActionAvatar from "@/components/video/action/VideoActionAvatar";
import VideoActionPage from "@/components/video/action/VideoActionPage";
import VideoActionShare from "@/components/video/action/VideoActionShare";
import VideoActionGift from "@/components/video/action/VideoActionGift";

export default {
    data() {
        return {
            swiper: null,
            loading: false,
            unsubscribeToAction: null,
            unsubscribeToMutations: null,
            initialized: false,
            scrollTimeout: null,
        };
    },
    beforeDestroy() {
        this.$mousetrap.unbind("up down");

        if (this.swiper) {
            this.swiper.destroy(true, true); // Destroy Swiper and remove all its events and references
            this.swiper = null;
        }
    },
    mounted() {
        // must come before refresh
        if (this.$route.params?.slug) {
            this.$store.commit("videoIndex/setPage", 1);
            this.$store.commit("videoIndex/setSlug", this.$route.params?.slug);
        }

        this.refresh();

        this.$mousetrap.bind("up", () => {
            this.prevSlide();
        });
        this.$mousetrap.bind("down", () => {
            this.nextSlide();
        });
    },
    watch: {
        $route(to) {
            if (to?.params?.slug !== this.slug) {
                console.log("slug change, refresh ");
                this.refresh();
            }
        },
    },
    computed: {
        ...mapState("videoPlayer", [
            "currentCreatorVideo",
            "sharing",
            "gifting",
        ]),
        ...mapState("videoIndex", [
            "creatorVideos",
            "reachedTheEnd",
            "currentSlidePosition",
            "page",
            "slug",
            "isPlaying",
        ]),
    },
    methods: {
        nextSlide() {
            if (!this.swiper) {
                return;
            }

            this.swiper.slideNext();
        },
        async prevSlide() {
            if (!this.swiper) {
                return;
            }

            // if at the beginning, jump back to previous cached results
            // and set the cursor at the last slide
            if (this.swiper.isBeginning) {
                if (this.page === 1) {
                    console.log("first slide of first page, non-op");
                    return;
                }

                // temporarily disable playback between page changes
                // otherwise the instant the new slides load, it will
                // start playing the video on slide 0
                // and transition without stopping it
                const originalValue = this.isPlaying;

                this.$store.commit("videoIndex/setIsPlaying", false);

                await this.$store.dispatch("videoIndex/prev");

                // the actual last slide is the loading screen for next page
                // so we want to go to second to last when we're scrolling back
                this.$nextTick(async () => {
                    const lastIndex = this.swiper.slides.length - 2;

                    await this.swiper.slideTo(lastIndex, 0);

                    this.$store.commit(
                        "videoIndex/setIsPlaying",
                        originalValue
                    );
                });
            } else {
                this.swiper.slidePrev();
            }
        },
        async initSwiper() {
            let vueContext = this;

            this.$nextTick(() => {
                let options = {
                    direction: "vertical",
                    slidesPerView: 1,
                    freeMode: false,
                    on: {
                        afterInit() {
                            vueContext.slideChanged(this);
                        },
                        slideChange() {
                            vueContext.slideChanged(this);
                        },
                        reachEnd() {
                            vueContext.endOfResults(this);
                        },
                    },
                };

                if (this.currentSlidePosition) {
                    options.initialSlide = this.currentSlidePosition;
                }

                this.swiper = new Swiper(this.$refs.videoIndexSwiper, options);
            });
        },
        async startOver() {
            await this.$store.dispatch("videoIndex/startOver");
            this.swiper.update();
        },
        next() {
            this.$store.dispatch("videoIndex/next");
        },
        async refresh() {
            await this.$store.dispatch("videoIndex/refresh");
            this.initSwiper();
        },
        slideChanged(swiper) {
            this.$store.dispatch(
                "videoIndex/setCurrentSlidePosition",
                swiper.activeIndex
            );

            this.$store.dispatch("videoIndex/view");

            this.$store.commit("videoPlayer/setSharing", false);
            this.$store.commit("videoPlayer/setGifting", false);
        },
        async endOfResults() {
            await this.$store.dispatch("videoIndex/next");

            this.swiper.update();
            this.swiper.slideTo(0, 0);
        },
    },
    components: {
        VideoView,
        VideoShareDialog,
        VideoActionFavorite,
        VideoToolbar,
        VideoActionAvatar,
        VideoActionPage,
        VideoActionShare,
        VideoGiftDialog,
        VideoActionGift,
    },
};
</script>
